import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { path } from 'ramda';
import { Link } from 'gatsby';
import { createSelector, createStructuredSelector } from 'reselect';
import { compose, withState, withProps, withHandlers } from 'recompose';

import { selectors, actions } from '/src/store/cart';
import CartItem from './CartItem';
import Table from './Table';
import Subtotal from './Subtotal';

import './index.scss';

const Cart = ({ items, subtotalString }) => (
  <div className="Cart">
    {items && items.length ? (
      <>
        {items.map(item => (
          <CartItem key={item.id} sku={item} />
        ))}
        <div className="space-top tr">{`Subtotal: ${subtotalString}`}</div>
      </>
    ) : (
      <div className="Cart__empty">
        Cart is empty. <Link to="/shop">Get some merch!</Link>
      </div>
    )}
  </div>
);

Cart.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      attributes: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      description: PropTypes.string,
      image: PropTypes.string,
    })
  ),
  // generated
  price: PropTypes.string.isRequired,
  handleQuantityChange: PropTypes.func.isRequired,
  addItem: PropTypes.func.isRequired,
};

Cart.defaultProps = {
  items: [],
};

const mapStateToProps = createStructuredSelector({
  items: selectors.itemsSelector,
  subtotalString: selectors.subtotalStringSelector,
});

const mapDispatchToProps = {
  addItem: actions.addItem,
};

const priceSelector = createSelector(
  path(['sku', 'price']),
  price => (price * 0.01).toFixed(2)
);

const propSelectors = createStructuredSelector({
  price: priceSelector,
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withState('quantity', 'setQuantity', 1),
  withProps(propSelectors),
  withHandlers({
    handleQuantityChange: ({ setQuantity }) => event =>
      setQuantity(event.target.value),
  })
)(Cart);

export { Subtotal, Table };
