// Using vanilla JS for server-side import
const slugify = require('slugify');
const createCachedSelector = require('re-reselect').default;

const PRODUCTS_PATH = '/shop/products';

module.exports = {
  productPath: createCachedSelector(
    i => i,
    productName => `${PRODUCTS_PATH}/${slugify(productName).toLowerCase()}`
  )(i => i),
};
