import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import { path } from 'ramda';
import { createSelector, createStructuredSelector } from 'reselect';
import { compose, withState, withProps, withHandlers } from 'recompose';

import { productPath } from '/src/utils/paths';
import { actions } from '/src/store/cart';
import { Row, Col } from '/src/components/atoms/GridRow';
import FormGroup from '/src/components/molecules/FormGroup';

import './index.scss';
import Button from '/src/components/atoms/Button';

const CartItem = ({ sku, price, handleQuantityChange, handleRemove }) => (
  <div className="CartItem">
    <Row>
      {sku.image && (
        <Col width={3} className="CartItem__image">
          <img className="img-responsive" src={sku.image} alt="" />
        </Col>
      )}
      <Col width={6} className="CartItem__details ml-auto">
        <h3 className="CartItem__title">
          <Link to={productPath(sku.product.name)}>{sku.product.name}</Link>
        </h3>
        <p className="CartItem__description">{sku.attributes.name}</p>
        <p className="CartItem__price tr">{`$${price}`}</p>
      </Col>
      <Col width={3} className="CartItem__action">
        <FormGroup
          id={`quantity__${sku.id}`}
          label="Quantity"
          value={sku.quantity}
          className="w-100"
          inputProps={{
            type: 'number',
            min: 0,
            onChange: handleQuantityChange,
          }}
        />
        <Button
          small
          link
          aria-label={`Remove ${sku.attributes.name} from cart`}
          onClick={handleRemove}
        >
          Remove
        </Button>
      </Col>
    </Row>
  </div>
);

CartItem.propTypes = {
  sku: PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  // generated
  price: PropTypes.string.isRequired,
  handleQuantityChange: PropTypes.func.isRequired,
  setItem: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  setItem: actions.setItem,
  removeItem: actions.removeItem,
};

const priceSelector = createSelector(
  path(['sku', 'price']),
  price => (price * 0.01).toFixed(2)
);

const propSelectors = createStructuredSelector({
  price: priceSelector,
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  withState('quantity', 'setQuantity', 1),
  withProps(propSelectors),
  withHandlers({
    handleQuantityChange: ({ sku: { id }, setItem }) => event =>
      setItem({
        id,
        quantity: event.target.value,
      }),
    handleRemove: ({ sku, removeItem }) => () => removeItem(sku),
  })
)(CartItem);
