import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { withProps } from 'recompose';
import { prop, isNil } from 'ramda';
import classNames from 'classnames';
import { MdArrowDropDown } from 'react-icons/md';

import DataTableItemRow from './DataTableItemRow';
import Tooltip from '/src/components/atoms/Tooltip';

import './index.scss';

class DataTable extends React.Component {
  constructor(props) {
    super(props);

    if (props.getCsv) {
      props.getCsv(() => () => csvSelector(props));
    }
  }

  static propTypes = {
    // required
    data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    keyProp: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        property: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
          .isRequired,
        renderItem: PropTypes.func,
      })
    ).isRequired,
    // optional
    onSelectChange: PropTypes.func,
    selectedData: PropTypes.shape(),
    limit: PropTypes.number,
    offset: PropTypes.number,
    lastRow: PropTypes.node,
    sortBy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isSortAscending: PropTypes.bool,
    onSortChange: PropTypes.func,
    rowNums: PropTypes.bool,
    loading: PropTypes.bool,
    striped: PropTypes.bool,
    stripedVertical: PropTypes.bool,
    getCsv: PropTypes.func,
  };

  static defaultProps = {
    keyProp: null,
    onSelectChange: null,
    selectedData: null,
    limit: null,
    offset: null,
    lastRow: null,
    sortBy: null,
    isSortAscending: false,
    onSortChange: null,
    rowNums: false,
    loading: false,
    striped: false,
    stripedVertical: false,
    getCsv: null,
  };

  getKey = (data, backupKey) => {
    const key = prop(this.props.keyProp, data);
    return !isNil(key) ? key : backupKey;
  };

  render() {
    const {
      columns,
      showTableHeaders = true,
      onSelectChange,
      selectedData,
      paginatedData,
      offset,
      lastRow,
      sortBy,
      isSortAscending,
      onSortChange,
      loading,
      striped,
      stripedVertical,
      rowNums,
    } = this.props;
    const isSelectable = !!onSelectChange;

    return (
      <table
        className={classNames({
          DataTable: true,
          'DataTable--loading': !!loading,
        })}
      >
        <thead className="DataTable__head">
          <tr className="DataTable__head__row">
            {rowNums && <td />}
            {showTableHeaders &&
              columns.map((col, index) => (
                <th
                  key={col.title}
                  className={classNames({
                    DataTable__head__cell: true,
                    'DataTable__head__cell--striped':
                      stripedVertical && (index + (rowNums ? 1 : 0)) % 2 === 1,
                  })}
                >
                  <div className="DataTable__head__cell__inner">
                    {onSortChange && !col.noSort ? (
                      <Tooltip
                        label="Sort"
                        className="yd-tooltip__mobile_hidden"
                      >
                        <button
                          aria-label={`Sort by ${col.title}`}
                          className="DataTable__head__cell__label dim pointer flex items-center"
                          onClick={() =>
                            onSortChange(col.title, isSortAscending, col)
                          }
                        >
                          <span>{col.title}</span>
                          {sortBy === col.title && (
                            <span
                              className={classNames({
                                ml2: true,
                                'DataTable__head__cell__sort-label': true,
                                'DataTable__head__cell__sort-label--asc': !!isSortAscending,
                              })}
                              aria-hidden="true"
                            >
                              <MdArrowDropDown />
                            </span>
                          )}
                        </button>
                      </Tooltip>
                    ) : (
                      <span className="DataTable__head__cell__label">
                        {col.title}
                      </span>
                    )}
                  </div>
                </th>
              ))}
          </tr>
        </thead>
        <tbody className="DataTable__body">
          {(paginatedData || []).map((item, index) => (
            <DataTableItemRow
              key={this.getKey(item, index)}
              item={item}
              nextItem={
                index + 1 < paginatedData.length
                  ? paginatedData[index + 1]
                  : null
              }
              columns={columns}
              handleSelect={onSelectChange}
              selectable={isSelectable}
              selected={
                isSelectable &&
                (selectedData &&
                  this.getKey(item) === this.getKey(selectedData))
              }
              rowNum={rowNums ? index + offset : null}
              striped={striped && (index + offset) % 2 === 0}
              stripedVertical={stripedVertical}
            />
          ))}
          {lastRow}
        </tbody>
      </table>
    );
  }
}

const paginatedDataSelector = createCachedSelector(
  props => props.offset,
  props => props.limit,
  props => props.data,
  (offset, limit, data) =>
    offset || limit
      ? data.slice(
          offset || 0,
          limit ? Math.min(offset + limit, data.length) : data.length
        )
      : data
)(
  ({ sortBy, isSortAscending, offset, limit }) =>
    `${sortBy}_${isSortAscending}_${offset}_${limit}`
);

const csvSelector = createCachedSelector(
  props => props.columns,
  props => props.data,
  (columns, data) => {
    const titlesRow = columns.map(column => `"${column.title}"`).join(',');

    const dataRowStrings = data.map(dataItem =>
      columns
        .map(col => {
          const displayProp = col.renderItem
            ? col.renderItem(dataItem, col)
            : typeof col.property === 'function'
            ? col.property(dataItem)
            : prop(col.property, dataItem);

          return `"${displayProp || ''}"`;
        })
        .join(',')
    );

    const csvString = [titlesRow, ...dataRowStrings].join('\n');
    const blob = new Blob([csvString], { type: 'octet/stream' });
    const downloadUrl = window.URL.createObjectURL(blob);

    return downloadUrl;
  }
)(
  ({ sortBy, isSortAscending, offset, limit }) =>
    `${sortBy}_${isSortAscending}_${offset}_${limit}`
);

const propSelectors = createStructuredSelector({
  paginatedData: paginatedDataSelector,
});

export default withProps(propSelectors)(DataTable);
