import React from 'react';
import PropTypes from 'prop-types';
import { withState } from 'recompose';

import TooltipDetached, { positions } from './detached';

export { TooltipDetached, positions };

class Tooltip extends React.Component {
  static propTypes = {
    // optional
    id: PropTypes.string,
    label: PropTypes.node,
    display: PropTypes.string,
    placement: PropTypes.oneOf(Object.values(positions)),
    delay: PropTypes.number,
    children: PropTypes.node,
    className: PropTypes.string,
    wrapperClass: PropTypes.string,
    // generated
    anchorEl: PropTypes.shape(),
  };

  static defaultProps = {
    id: null,
    label: null,
    display: 'inherit',
    placement: positions.TOP,
    delay: 0,
    children: null,
    className: null,
    wrapperClass: null,
    anchorEl: null,
  };

  componentDidMount() {
    this.props.setAnchorEl(this.wrapperRef);
  }

  render() {
    const {
      id,
      label,
      children,
      anchorEl,
      delay,
      display,
      placement,
      className,
      wrapperClass,
    } = this.props;

    return (
      <React.Fragment>
        {anchorEl && (
          <TooltipDetached
            anchorEl={anchorEl}
            label={label}
            id={id}
            placement={placement}
            delay={delay}
            className={className}
          />
        )}
        <span
          ref={ref => {
            this.wrapperRef = ref;
          }}
          style={{ display: display || 'inherit' }}
          className={wrapperClass}
        >
          {children}
        </span>
      </React.Fragment>
    );
  }
}

export default withState('anchorEl', 'setAnchorEl', null)(Tooltip);
