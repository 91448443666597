import React from 'react';
import PropTypes from 'prop-types';
import { prop, isNil } from 'ramda';
import { compose, withState, withHandlers } from 'recompose';
import classNames from 'classnames';

export const DataTableItemRow = ({
  item,
  columns,
  selected,
  isFocused,
  handleSelect,
  rowNum,
  nextItem,
  striped,
  stripedVertical,
  handleFocus,
  handleBlur,
}) => (
  <tr
    className={classNames({
      DataTable__body__row: true,
      'DataTable__body__row--selected': !!selected,
      'DataTable__body__row--focused': !!isFocused,
      'DataTable__body__row--clickable': !!handleSelect,
    })}
    selected={selected}
    {...(handleSelect && {
      tabIndex: -1,
      onFocus: handleFocus,
      onBlur: handleBlur,
      onClick: () => handleSelect(item),
    })}
  >
    {rowNum !== null && (
      <td className="DataTable__body__cell DataTable__body__cell--number">
        {rowNum + 1}
      </td>
    )}
    {columns.map((col, index) => {
      const itemString =
        typeof col.property === 'function'
          ? col.property(item, col)
          : prop(col.property, item);

      const cellContent = col.renderItem
        ? col.renderItem(item, col, nextItem)
        : itemString;

      return (
        <td
          key={col.title || index}
          className={classNames({
            tr: col.alignRight ? col.alignRight : false,
            DataTable__body__cell: true,
            'DataTable__body__cell--striped':
              (stripedVertical &&
                (index + (!isNil(rowNum) ? 1 : 0)) % 2 === 1) ||
              striped,
          })}
        >
          {handleSelect && index === 0 && (
            <button
              className="DataTable__body__cell__button"
              onClick={() => handleSelect(item)}
              aria-label={`Select item with ${col.title}: ${itemString}`}
            />
          )}
          {cellContent}
        </td>
      );
    })}
  </tr>
);

DataTableItemRow.propTypes = {
  item: PropTypes.shape().isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      property: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
        .isRequired,
      renderItem: PropTypes.func,
    })
  ).isRequired,
  nextItem: PropTypes.shape(),
  selected: PropTypes.bool,
  handleSelect: PropTypes.func,
  selectable: PropTypes.bool,
  rowNum: PropTypes.number,
  striped: PropTypes.bool,
};

DataTableItemRow.defaultProps = {
  nextItem: null,
  handleSelect: null,
  selected: false,
  selectable: false,
  rowNum: null,
  striped: false,
};

export default compose(
  withState('isFocused', 'setFocused', false),
  withHandlers({
    handleFocus: ({ setFocused }) => () => setFocused(true),
    handleBlur: ({ setFocused }) => () => setFocused(false),
  })
)(DataTableItemRow);
