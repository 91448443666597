import React from 'react';
import { isNil, prop } from 'ramda';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { elementType } from 'prop-types-extra';
import { compose, withState, withProps, withHandlers } from 'recompose';
import { createSelector, createStructuredSelector } from 'reselect';

import FormErrors from './FormErrors';
import './index.scss';

const errorId = id => `${id}__errors`;

const FormGroup = ({
  id,
  label,
  finalValue: value,
  inputComponent: Input,
  inputProps,
  errors,
  focused,
  setFocused,
  small,
  medium,
  inline,
  dark,
  className,
  noPlaceholder,
  handleChange,
}) => {
  const { className: inputClassName, ...finalInputProps } = inputProps || {};

  return (
    <div
      className={classNames('form__group', {
        'form__group--error': !!errors,
        'form__group--small': small,
        'form__group--medium': medium,
        'form__group--inline': inline,
        'form__group--on-dark': dark,
        [className]: !!className,
      })}
    >
      <label
        htmlFor={id}
        className={classNames('form__group__label', {
          'form__group__label--placeholder':
            !noPlaceholder && !focused && !value && Input === 'input',
        })}
      >
        {label}
      </label>
      <div className="form__group__field">
        <Input
          onChange={handleChange}
          value={value}
          {...finalInputProps}
          id={id}
          {...(!!errors && {
            'aria-invalid': true,
            'aria-describedby': errorId(id),
          })}
          className={classNames({
            form__group__field__control: true,
            [inputClassName]: !!inputClassName,
          })}
          onFocus={() => setFocused(true)}
          onBlur={event => {
            setFocused(false);
            if (typeof inputProps.onBlur === 'function') {
              inputProps.onBlur(event);
            }
          }}
        />
      </div>
      {!!errors && (
        <div className="form__group__error" id={errorId(id)}>
          {errors}
        </div>
      )}
    </div>
  );
};

FormGroup.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  inputComponent: elementType,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  inputProps: PropTypes.shape(),
  errors: PropTypes.string,
  focused: PropTypes.bool.isRequired,
  setFocused: PropTypes.func.isRequired,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  inline: PropTypes.bool,
  dark: PropTypes.bool,
  noPlaceholder: PropTypes.bool,
  className: PropTypes.string,
};

FormGroup.defaultProps = {
  value: undefined,
  inputProps: {},
  errors: null,
  inputComponent: 'input',
  small: false,
  medium: false,
  inline: false,
  dark: false,
  noPlaceholder: false,
  className: null,
};

export { FormErrors };

const valueSelector = createSelector(
  prop('value'),
  prop('valueInternal'),
  (valuePassed, valueInternal) =>
    !isNil(valuePassed) ? valuePassed : valueInternal
);

const propSelectors = createStructuredSelector({
  finalValue: valueSelector,
});

export default compose(
  withState('valueInternal', 'setValueInternal', ''),
  withState('focused', 'setFocused', false),
  withProps(propSelectors),
  withHandlers({
    handleChange: ({ setValueInternal, onChange }) => event => {
      setValueInternal(event.target.value);
      if (onChange) {
        onChange(event);
      }
    },
  })
)(FormGroup);
