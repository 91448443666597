import React from 'react'
import PropTypes from 'prop-types'

/*
  PROPS
    timerValue: current elapsed time
    initTimer(): starts the timer
    pauseTimer(): pauses the timer
    resumeTimer(): resumes the timer
    resetTimer(): resets the timer to 0
    stopTimer(): pauses & resets the timer
    setTimer(val): sets a timer value

  USAGE
    ```
    withTimer(MyComponent)
    ```  
*/

export const propTypes = {
  timerValue: PropTypes.number.isRequired,
  initTimer: PropTypes.func.isRequired,
  pauseTimer: PropTypes.func.isRequired,
  resumeTimer: PropTypes.func.isRequired,
  resetTimer: PropTypes.func.isRequired,
  stopTimer: PropTypes.func.isRequired,
  setTimer: PropTypes.func.isRequired
}

export default WrappedComponent =>
  class extends React.Component {
    static displayName = `WithTimer(${WrappedComponent.displayName ||
      WrappedComponent.name ||
      'Component'})`

    rafRef = null
    startTime = null
    valueInitial = 0
    stopped = true
    paused = true

    state = {
      currentValue: this.valueInitial
    }

    onTick = () => {
      const timestamp = +new Date()

      if (this.tickLock || this.stopped || this.paused) {
        return
      }

      if (!this.startTime) {
        this.startTime = +new Date()
      }

      this.setState({
        currentValue: this.valueInitial + timestamp - this.startTime
      })

      this.rafRef = window.requestAnimationFrame(this.onTick)
    }

    init = () => {
      this.stopped = false
      this.paused = false
      this.startTime = +new Date()
      this.rafRef = window.requestAnimationFrame(this.onTick)
    }

    pause = () => {
      this.paused = true
      this.valueInitial = this.state.currentValue
      window.cancelAnimationFrame(this.rafRef)
    }

    resume = () => {
      if (!this.stopped) {
        this.paused = false
        this.startTime = +new Date()
        this.rafRef = window.requestAnimationFrame(this.onTick)
      }
    }

    reset = () => {
      this.set(0)
      this.valueInitial = 0
      this.setState({ currentValue: 0 })
      this.paused = null
    }

    stop = () => {
      this.stopped = true
      this.pause()
      this.reset()
    }

    set = value => {
      this.startTime = +new Date() - value
    }

    render() {
      return (
        <WrappedComponent
          timerValue={this.state.currentValue}
          initTimer={this.init}
          pauseTimer={this.pause}
          paused={this.paused}
          resumeTimer={this.resume}
          resetTimer={this.reset}
          stopTimer={this.stop}
          setTimer={this.set}
          {...this.props}
        />
      )
    }
  }
