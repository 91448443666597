import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { path } from 'ramda';
import { Link } from 'gatsby';

import { productPath } from '/src/utils/paths';
import { selectors } from '/src/store/cart';
import DataTable from '/src/components/molecules/DataTable';

const columns = [
  {
    title: 'Item',
    property: path(['attributes', 'name']),
    renderItem: sku => (
      <>
        <div className="heading4">
          <Link to={productPath(sku.product.name)}>{sku.product.name}</Link>
        </div>
        <div>{sku.attributes.name}</div>
      </>
    ),
  },
  {
    title: 'Price',
    property: 'price',
    renderItem: sku => `$${(sku.price * 0.01).toFixed(2)}`,
  },
  {
    title: 'Quantity',
    property: 'quantity',
  },
];

const SummaryTable = ({ skus }) => (
  <DataTable keyProp="id" data={skus} columns={columns} />
);

const mapStateToProps = createStructuredSelector({
  skus: selectors.itemsSelector,
});

export default connect(mapStateToProps)(SummaryTable);
