import React from 'react';
import { Link } from 'gatsby';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectors } from '/src/store/cart';
import { Row, Col } from '/src/components/atoms/GridRow';
import Layout from '/src/components/layout';
import SEO from '/src/components/atoms/Seo';
import ContentSection from '/src/components/atoms/ContentSection';
import Button from '/src/components/atoms/Button';
import Cart from '/src/components/organisms/Cart';

const CartPage = ({ cartSize }) => (
  <Layout hideHeader>
    <SEO title="Cart | Shop" />
    <ContentSection>
      <h1 className="heading3">Cart</h1>
    </ContentSection>
    <ContentSection light>
      <Row center vertGutter>
        <Col width={6}>
          <h2 className="heading4">Your Cart</h2>
          <Cart />
          {cartSize > 0 && (
            <div className="space-top">
              <Button
                component={Link}
                to="/shop/checkout"
                className="w-100"
              >
                Checkout
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </ContentSection>
  </Layout>
);

const mapStateToProps = createStructuredSelector({
  cartSize: selectors.cartSizeSelector,
});

export default connect(mapStateToProps)(CartPage);
