import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectors } from '/src/store/cart';

const Subtotal = ({ subtotalString }) => <span>{subtotalString}</span>;

const mapStateToProps = createStructuredSelector({
  subtotalString: selectors.subtotalStringSelector,
});

export default connect(mapStateToProps)(Subtotal);
