import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const FormErrors = ({ errors }) =>
  errors ? (
    errors.length === 1 ? (
      <div className="form-errors">{errors[0]}</div>
    ) : (
      <ul>
        {errors.map(error => (
          <li key={error}>{error}</li>
        ))}
      </ul>
    )
  ) : null;

FormErrors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
};

FormErrors.defaultProps = {
  errors: [],
};

export default FormErrors;
