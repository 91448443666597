import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { compose, withState, withProps, withHandlers } from 'recompose';
import { createSelector, createStructuredSelector } from 'reselect';
import classNames from 'classnames';

import withTimer, {
  propTypes as timerProps,
} from '/src/components/hocs/withTimer';
import './index.scss';

export const positions = {
  TOP: 'top',
  BOTTOM: 'bottom',
};

class TooltipDetached extends React.Component {
  static propTypes = {
    // required
    anchorEl: PropTypes.shape().isRequired,
    // optional
    id: PropTypes.string,
    delay: PropTypes.number,
    label: PropTypes.node,
    windowMargin: PropTypes.number,
    placement: PropTypes.oneOf(Object.values(positions)),
    // generated
    left: PropTypes.number.isRequired,
    top: PropTypes.number.isRequired,
    ...timerProps,
  };

  static defaultProps = {
    id: null,

    delay: 0,
    label: null,
    windowMargin: 0,
    placement: positions.TOP,
  };

  getCoords = () => {
    const {
      anchorEl,
      setLeft,
      setAdjustX,
      setAdjustY,
      setTop,
      windowMargin,
      finalPlacement,
    } = this.props;

    if (!!anchorEl && !!anchorEl.getBoundingClientRect) {
      const rect = anchorEl.getBoundingClientRect();
      const tooltipRect = this.tooltipRef
        ? this.tooltipRef.getBoundingClientRect()
        : {};

      const left = window.scrollX + rect.left + rect.width / 2;
      const top =
        window.scrollY +
        rect.top +
        (finalPlacement === positions.BOTTOM ? rect.height : 0);

      const overflowRight =
        left + tooltipRect.width / 2 - window.innerWidth + windowMargin;
      const overflowLeft = left - tooltipRect.width / 2 - windowMargin;
      const adjustX =
        overflowRight > 0 ? overflowRight : overflowLeft < 0 ? overflowLeft : 0;

      const overflowTop = tooltipRect.height - top;
      const overflowBottom = window.innerHeight - (tooltipRect.height + top);
      const adjustY =
        overflowTop > 0 ? overflowTop : overflowBottom < 0 ? overflowBottom : 0;

      setLeft(left - adjustX);
      setAdjustX(adjustX);
      setAdjustY(adjustY);
      setTop(top);
    }
  };

  attachAnchorEvents = anchorEl => {
    const {
      handleMouseOver,
      handleMouseOut,
      handleFocus,
      handleBlur,
    } = this.props;

    anchorEl.addEventListener('mouseover', handleMouseOver);
    anchorEl.addEventListener('mouseout', handleMouseOut);
    anchorEl.addEventListener('focusin', handleFocus);
    anchorEl.addEventListener('focusout', handleBlur);
  };

  detachAnchorEvents = anchorEl => {
    const {
      handleMouseOver,
      handleMouseOut,
      handleFocus,
      handleBlur,
    } = this.props;

    anchorEl.removeEventListener('mouseover', handleMouseOver);
    anchorEl.removeEventListener('mouseout', handleMouseOut);
    anchorEl.removeEventListener('focusin', handleFocus);
    anchorEl.removeEventListener('focusout', handleBlur);
  };

  componentDidMount() {
    const { anchorEl } = this.props;

    this.attachAnchorEvents(anchorEl);
    this.getCoords();
  }

  componentWillUnmount() {
    const { anchorEl, stopTimer } = this.props;

    stopTimer();
    this.detachAnchorEvents(anchorEl);
  }

  componentDidUpdate(prevProps) {
    const {
      isShowing,
      anchorEl,
      isActive,
      initTimer,
      pauseTimer,
      stopTimer,
    } = this.props;
    const {
      isActive: prevIsActive,
      isShowing: prevShowing,
      anchorEl: prevAnchorEl,
    } = prevProps;

    if (isActive !== prevIsActive) {
      if (isActive) {
        initTimer();
      } else {
        stopTimer();
      }
    }

    if (isShowing !== prevShowing) {
      if (isShowing) {
        this.getCoords();
        pauseTimer();
      }
    }

    if (anchorEl !== prevAnchorEl) {
      this.attachAnchorEvents(anchorEl);
      if (!!prevAnchorEl) {
        this.detachAnchorEvents(prevAnchorEl);
      }
    }
  }

  render() {
    const {
      id,
      label,
      isShowing,
      left,
      adjustX,
      top,
      finalPlacement,
      className,
    } = this.props;

    return (
      typeof window === 'object' &&
      createPortal(
        label && (
          <div
            id={id}
            className={classNames({
              Tooltip: true,
              'Tooltip--showing': !!isShowing,
              [`Tooltip--placement-${finalPlacement}`]: !!finalPlacement,
              [className]: !!className,
            })}
            aria-hidden="true"
            ref={ref => {
              this.tooltipRef = ref;
            }}
            style={{
              left,
              top,
            }}
          >
            {label}
            <span
              role="presentation"
              className="Tooltip__arrow"
              style={{
                left: `calc(50% + ${adjustX}px)`,
              }}
            />
          </div>
        ),
        window.document.getElementById('portal__tooltip')
      )
    );
  }
}

const isActiveSelector = createSelector(
  props => props.isFocused,
  props => props.isHovered,
  (isFocused, isHovered) => isFocused || isHovered
);

const finalPlacementSelector = createSelector(
  props => props.placement,
  props => props.adjustY,
  (placement, adjustY) =>
    adjustY > 0 ? positions.BOTTOM : adjustY < 0 ? positions.TOP : placement
);

const isShowingSelector = createSelector(
  isActiveSelector,
  props => props.delay,
  props => props.timerValue,
  (isActive, delay, timerValue) =>
    isActive && (!(delay > 0) || timerValue >= delay)
);

const propSelectors = createStructuredSelector({
  isActive: isActiveSelector,
  isShowing: isShowingSelector,
  finalPlacement: finalPlacementSelector,
});

export default compose(
  withState('left', 'setLeft', 0),
  withState('adjustX', 'setAdjustX', 0),
  withState('adjustY', 'setAdjustY', 0),
  withState('top', 'setTop', 0),
  withState('isFocused', 'setFocused', false),
  withState('isHovered', 'setHovered', false),
  withTimer,
  withHandlers({
    handleMouseOver: ({ setHovered }) => () => setHovered(true),
    handleMouseOut: ({ setHovered }) => () => setHovered(false),
    handleFocus: ({ setFocused }) => () => setFocused(true),
    handleBlur: ({ setFocused }) => () => setFocused(false),
  }),
  withProps(propSelectors)
)(TooltipDetached);
